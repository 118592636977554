.nav {
   width: 100%;
   background-color: #fff;
   height: 75px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 30px;
   box-shadow: -1px 2px 3px 0px rgba(0, 0, 0, 0.05);
}

.list {
   a {
      text-decoration: none;
      color: #3e3e3e;
      margin: 0 15px;
   }

   a:hover {
      color: #e83741;
   }

   .activeLink {
      color: #e83741;
      font-weight: 500;
   }
}
