.toggleContainer {
   display: block;
   position: relative;
   padding-left: 35px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   height: 19px;
   margin: 5px 10px;
}
.toggleContainer input {
   position: absolute;
   z-index: 10;
   top: 0;
   left: 0;
   opacity: 0;
   cursor: pointer;
   height: 19px;
   width: 35px;
}
.toggleContainer input:checked ~ .span {
   background-color: rgb(14, 167, 0);
}
.toggleContainer input:checked ~ .span:after {
   left: 19px;
}
.toggleContainer .span {
   position: absolute;
   top: 0;
   left: 0;
   height: 19px;
   width: 35px;
   border-radius: 9.5px;
   background-color: rgb(219, 0, 0);
   transition: background-color 0.35s ease-in;
}
.toggleContainer .span:after {
   content: "";
   position: absolute;
   left: 3px;
   top: 3px;
   width: 13px;
   height: 13px;
   border-radius: 9.5px;
   background: white;
   transition: left 0.35s ease-in;
}
