.container {
   width: 90%;
   max-width: 420px;
   border-radius: 20px;
   background-color: #fff;
   padding: 20px;
}
.container,
.form {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   button {
      margin-top: 10px;
   }
}

.form {
   width: 100%;
}
