.container {
   background-color: #fff;
   padding: 30px;
   border-radius: 20px;
   margin: 30px 0;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
   width: 100%;
   display: flex;
   flex-direction: column;

   min-height: 300px;

   h3 {
      color: #e00000;
      margin-bottom: 20px;
   }
}
.header {
   display: flex;
   width: 100%;
   justify-content: space-between;
}

.modalContainer {
   background-color: #fff;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
   width: 90%;
   border-radius: 20px;
   max-width: 420px;
   padding: 20px;

   &,
   .form {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
         margin-top: 10px;
      }
   }
}

.form {
   width: 100%;
}
