.container {
   background-color: #fff;
   padding: 30px;
   border-radius: 20px;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);

   h3 {
      color: #e00000;
      margin-bottom: 20px;
   }
}

.form {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;

   button {
      align-self: flex-end;
      margin-top: 20px;
   }
}

.inputContainer {
   max-width: none;
}
