.container {
   background-color: #fff;
   padding: 20px;
   border-radius: 20px;
   width: 100%;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
}

.btnsContainer {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   .btn {
      padding: 5px 15px;
      margin: 0 20px;
      border: 1px solid #e83741;
      border-radius: 15px;
      font: 400 16px "Poppins";
      letter-spacing: 0.01em;
      color: #3e3e3e;
   }

   .activeBtn {
      background-color: #e83741;
      color: #fff;
   }
}

.searchInput {
   max-width: none;
   padding: 0 10px;
   margin: 20px 0 0;
}

.spinnerContainer {
   height: 650px;
}

.imageAndName {
   img {
      width: 35px;
      height: 35px;
      margin: 0 10px 0 5px;
      border-radius: 50%;
      box-shadow: 0px 3px 5px 0px rgba(166, 166, 166, 0.24);
      object-fit: cover;
   }
}
