.container {
   padding: 20px 10px;
   margin: 10px 0;

   p {
      text-align: center;
   }
}

.table {
   width: 100%;
   display: block;
   overflow-x: auto;
   white-space: nowrap;
   border-collapse: collapse;
   font: 400 15px "Poppins";
   color: rgba(0, 0, 0, 0.78);

   thead > tr {
      height: 30px;
   }

   th {
      text-align: start;
      font-weight: 500;
      font-size: 14px;
      color: #a1a1a1;
      text-transform: uppercase;
   }

   tbody > tr {
      height: 50px;
      border-bottom: 1px solid #f1f1f1;

      &:hover {
         background-color: #fafafa;
      }
   }

   td {
      padding: 5px 0;
      text-overflow: ellipsis;
      white-space: normal;
   }
}

.pagination {
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;
   margin-top: 20px;

   button {
      margin: 0 15px;
      font-size: 20px;
      font-weight: 500;
      background-color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;

      &:hover:not([disabled]) {
         background-color: rgba(0, 0, 0, 0.08);
      }

      &:disabled {
         cursor: default;
      }
   }
}
