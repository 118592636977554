.inputContainer {
   width: 100%;
   max-width: 350px;
   margin: 12px 0px;
   display: inline-block;
}

.fieldContainer {
   position: relative;
}

.input {
   padding: 12px 16px;
   border: 1px solid #b8b8b8;
   border-radius: 4px;
   width: 100%;
   height: 48px;
   font: 300 14px "Poppins";
   color: #666666;
}

.label {
   color: #5e6368;
   position: absolute;
   pointer-events: none;
   top: 50%;
   transform: translateY(-50%);
   left: 16px;
   transition: all 0.2s ease;
}

.input:hover {
   border: 1px solid #7a7a7a;
}

.input:focus {
   /* .input:valid { */
   outline: none;
   border: 1px solid #7a7a7a;
}

.input:not(:focus):not(:placeholder-shown) ~ .label,
.input:focus ~ .label {
   top: -10px;
   transform: none;
   bottom: 0px;
   left: 12px;
   background-color: #fff;
   font: 400 12px "Poppins";
   opacity: 1;
   color: #666666;
   padding: 0 4px;
   height: 15px;
}

.input:not(:focus):valid ~ .label {
   color: #cfcfcf;
}

.icon {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   left: 16px;
}

.icon ~ .input {
   padding-left: 52px;
}

.icon ~ .label {
   left: 52px;
}

.errorInput {
   border: 1px solid #d51527;
}

.errMsg {
   color: #d51527;
   font: 400 12px "Poppins";
   margin: 4px 0 0 16px;
}

#required {
   color: #e00000;
}

label.label.labelErr {
   color: #d51527 !important;
}
