.container {
   width: 90%;
   max-width: 550px;
   background-color: #fff;
   border-radius: 20px;
   padding: 20px;
   p {
      text-align: center;
      margin: 10px;
      font: 400 14px "Poppins";
      color: #3e3e3e;
   }
   button {
      margin: 10px 5px 5px;
   }
}
