@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: "Poppins", sans-serif;
   &::-webkit-scrollbar {
      width: 0.6rem;
      height: 0.6rem;
   }
   &::-webkit-scrollbar-track {
      background: #fafafa;
   }
   &::-webkit-scrollbar-thumb {
      background: #c9333c;
      border-radius: 8px;
   }
}

a {
   text-decoration: none;
}

button {
   border: none;
   background: transparent;
   cursor: pointer;
}

html,
body,
#root {
   height: 100%;
   width: 100%;
   background-color: #fafafa;
}

.dflex {
   display: flex;
}

.dfc {
   display: flex;
   justify-content: center;
   align-items: center;
}

.flexCol {
   flex-direction: column;
}

.alignCenter {
   align-items: center;
}

.justifyBetween {
   justify-content: space-between;
}
.w100 {
   width: 100%;
}
.h100 {
   height: 100%;
}

.redSvg svg path {
   fill: rgb(219, 0, 0);
}

.mx-10 {
   margin: 0 10px;
}
