.page {
   height: 100%;
   width: 100%;
   background-image: linear-gradient(to bottom, #fffbd5, #ff003360);
   display: flex;
   justify-content: center;
   align-items: center;
}

.form {
   background-color: #fff;
   padding: 50px 30px;
   max-width: 400px;
   width: 90%;
   border-radius: 20px;
   box-shadow: 1px 5px 22px -6px rgba(0, 0, 0, 0.13);
}
