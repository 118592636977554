.alertsContainer {
   position: fixed;
   top: 60px;
   width: 100%;
   display: flex;
   align-items: center;
   flex-direction: column;
   z-index: 110;
}

.alert {
   display: flex;
   align-items: center;
   text-align: center;
   padding: 10px;
   max-width: 600px;
   color: #fdfdfd;
   font: 500 15px "Poppins";
   background: rgb(75, 177, 61);
   margin: 10px 5px;
   border-radius: 15px;

   svg {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 14px;
      width: 14px;
      margin-right: 10px;
      path {
         fill: #fff;
      }
   }
}

.alert p {
   margin: 0 16px;
   line-height: 24px;
}

img.icon {
   cursor: default;
}

.error {
   background: #fc404f;
}

.warning {
   background: #f6e093;
}
