.container {
   border-radius: 20px;
   background-color: #fff;
   justify-content: flex-start;
   padding: 20px 5px;
}

.personalInfo {
   color: #3e3e3e;
   width: fit-content;
   min-width: 250px;
   padding: 20px;
   border-right: 1px solid #dadada;
   justify-content: flex-start;
   img {
      width: 150px;
      height: 150px;
      box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 20px;
   }

   h3 {
      color: #616161;
   }

   a {
      color: #8b8b8b;
   }

   h6 {
      color: #8b8b8b;
      font: 400 14px "Poppins";
      margin-top: 7px;
      letter-spacing: 0.01em;
   }
   p {
      color: #8b8b8b;
      margin-top: 10px;
      font: 300 12px "Poppins";
   }
   a:hover,
   a > h6:hover {
      color: #2e2e2e;
   }
}

.content {
   padding: 10px 20px;
   overflow-x: auto;
   width: 100%;
   flex: 2;

   h3 {
      font: 600 18px "Poppins";
   }
}

.infoIcon {
   margin-left: 10px;
}
.iconContainer {
   display: flex;
   align-items: center;
}

.iconButton {
   padding: 8;
   top: 0px;
   width: 22px;
   height: 22px;
}

.marginInput {
   margin-top: 20px;
   margin-bottom: 10px;
}

.marginBottom {
   margin-bottom: 20px;
}
