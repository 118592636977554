.container {
   background-color: #fff;
   padding: 30px;
   border-radius: 20px;
   width: 100%;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
   min-height: fit-content;
   margin: 20px 0;
}

.heading {
   color: #e00000;
   margin-bottom: 10px;
}

.spinnerContainer {
   height: 500px;
}

.searchInput {
   max-width: none;
   padding: 0 10px;
   margin: 20px 0 0;
}

.active,
.inactive {
   width: 7px;
   height: 7px;
   border-radius: 50%;
   background-color: rgb(14, 167, 0);
   margin-right: 7px;
}

.inactive {
   background-color: rgb(219, 0, 0);
}

.modalContainer {
   padding: 20px;
   background-color: #fff;
   border-radius: 20px;
   width: 100%;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
   max-width: 400px;
   button {
      margin-top: 20px;
   }
}

.toggleCont {
   align-self: flex-start;
   display: flex;
   align-items: center;
   padding: 10px;
   padding-bottom: 0;
   color: #3e3e3e;
}
.uploadPicture{
   border: 1px dashed gray;
   width: 95%;
   height:130px;
   margin-top: 20px;
   border-radius: 5px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   input[type="file"] {
      display: none;
  }
  >img{
   width: auto;
   height: 70px;
  }

  >label{
   cursor: pointer;
   color:#e83741;
   border: 1px solid #e83741;
   border-radius: 5px;
   padding:3px 5px;
   width: fit-content;
   margin-top: 5px;
   font-size: 13px;
  }
}
.toggleButton{
   width: 100%;
   margin-top: 20px;
   display: flex;
   align-items: center;
   >label{
      color: gray;
   }
}