.container {
   background-color: #fff;
   padding: 20px;
   border-radius: 20px;
   width: 100%;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
   min-height: fit-content;
}

.spinnerContainer {
   height: 500px;
}

.searchInput {
   max-width: none;
   padding: 0 10px;
   margin: 20px 0 0;
}
