.btn {
   background-color: #e83741;
   height: 48px;
   border: none;
   border-radius: 12px;
   padding: 12px 24px;
   font: 500 16px "Poppins";
   line-height: 24px;
   color: #fff;
   cursor: pointer;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.07);
}

.btn:hover {
   background-color: #f84d55;
}

.btn:disabled {
   background-color: #faa2a7;
   cursor: default;
}

.medium {
   font: 400 14px "Poppins";
   padding: 8px 16px;
   height: 36px;
}

.inversed {
   color: #e83741;
   background-color: #fff;

   &:hover {
      background-color: #fafafa;
   }
}
