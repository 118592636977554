.container {
   height: 70px;
   display: flex;
   justify-content: space-around;
   align-items: center;
   background: #fff;
   box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.03);
   margin: 20px 0;
   border-radius: 20px;

   p {
      font-size: 16px;
      margin: 0 15px;
      font-weight: 400;

      span {
         font-weight: 600;
      }
   }
}
